<template>
  <div class="container how">
    <div class="row mb-3">
      <div class="col-12 how-title">How it works</div>
    </div>

    <div class="row mb-3">
      <div class="col-12 how-sub-title">
        Get your profile ready in 4 easy steps
      </div>
    </div>

    <div class="how-tabs">
      <div
        :class="{ 'how-action': true, active: tab.step == activeTab }"
        v-for="tab in tabs"
        :key="tab.step"
        @click.prevent.stop="switchTab(tab.step)"
      >
        <span class="step">Step {{ tab.step }}</span>
        <span class="title">{{ tab.title }}</span>
      </div>
    </div>

    <div class="how-tabs-desc row mt-5 text-center pb-3 border-bottom">
      <div class="col-12" v-show="activeTab == 1">
        <img
          src="../../assets/create-account.png"
          srcset="
            ../../assets/create-account@2x.png 2x,
            ../../assets/create-account@3x.png 3x
          "
          class="img-fluid"
          alt="Create account"
        />
      </div>

      <div class="col-12 build-cv" v-show="activeTab == 2">
        <div class="row">
          <div class="col-6 mt-md-5">
            <img
              src="../../assets/steps.png"
              srcset="
                ../../assets/steps@2x.png 2x,
                ../../assets/steps@3x.png 3x
              "
              class="img-fluid"
              alt="Steps"
            />
          </div>

          <div class="col-6 mt-md-n4 ml-md-n5">
            <img
              src="../../assets/career-interests.png"
              srcset="
                ../../assets/career-interests@2x.png 2x,
                ../../assets/career-interests@3x.png 3x
              "
              alt="Career interests"
              class="img-fluid"
            />
          </div>
        </div>

        <div class="row mt-md-n5">
          <div class="col-md-6 mt-md-10 skills">
            <img
              src="../../assets/skills.png"
              srcset="
                ../../assets/skills@2x.png 2x,
                ../../assets/skills@3x.png 3x
              "
              alt="Skills"
            />
          </div>

          <div class="sign-up">
            <div class="title">Khibra CV builder</div>
            <div class="content mt-3">
              Backed by expert recommendations, KHIBRA helps you get the most
              out of your CV
            </div>
            <Button
              @clicked="goToApp()"
              class="mt-4 text-white border font-weight-bold font-18"
              type="big"
              color="transparent"
              >Sign up now</Button
            >
          </div>
        </div>
      </div>

      <div class="w-100" v-show="activeTab == 3">
        <div class="job-search">
          <img
            src="../../assets/search-jobs.png"
            srcset="
              ../../assets/search-jobs@2x.png 2x,
              ../../assets/search-jobs@3x.png 3x
            "
            class="img-fluid"
            alt="Search jobs"
          />
          <div class="sign-up">
            <div class="title">Khibra job search</div>
            <div class="content mt-3">
              Easily browse and search for your next job that matches your
              interest across the Middle East
            </div>
            <Button
              @clicked="goToApp()"
              class="mt-4 text-white border font-weight-bold font-18"
              type="big"
              color="transparent"
              >Sign up now</Button
            >
          </div>
        </div>
      </div>

      <div class="col-12" v-show="activeTab == 4">
        <img
          src="../../assets/apply.png"
          srcset="../../assets/apply@2x.png 2x, ../../assets/apply@3x.png 3x"
          class="img-fluid"
          alt="Apply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/util/Button";

export default {
  name: "HowItWorks",

  components: {
    Button,
  },

  data() {
    return {
      activeTab: 1,
      tabs: [
        {
          step: 1,
          title: "Create Account",
        },
        {
          step: 2,
          title: "Build Your CV",
        },
        {
          step: 3,
          title: "Search For Jobs",
        },
        {
          step: 4,
          title: "Apply!",
        },
      ],
    };
  },

  // mounted() {
  //   setInterval(() => {
  //     if (this.activeTab === this.tabs?.length) {
  //       this.activeTab = 1;
  //     } else {
  //       this.activeTab = this.activeTab + 1;
  //     }
  //   }, 5000);
  // },

  methods: {
    switchTab(tab) {
      this.activeTab = tab;
    },

    goToApp() {
      window.open(process.env.VUE_APP_URL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.how {
  &-title {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
  }

  &-sub-title {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
  }

  &-tabs {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &-desc {
      height: 650px;
    }
  }

  &-action {
    margin: 10px 40px;
    cursor: pointer;

    &.active {
      color: $blue;
      border-bottom: solid 3px $blue;
      cursor: default;
    }

    .step {
      display: block;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
    }
  }
}

.sign-up {
  width: 390px;
  height: 100%;
  padding: 32px;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
  background-color: $blue;
  color: #ffffff;
  text-align: center;

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
  }

  .content {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
}

.build-cv {
  background: url("../../assets/circles-logo-alt.svg") center no-repeat;
  background-size: 50%;

  .sign-up {
    margin-left: 150px;
  }

  .skills {
    img {
      margin-left: 200px;
    }
  }
}

.job-search {
  background: url("../../assets/circles-logo@3x.png") center no-repeat;
  background-size: contain;
  position: relative;
  display: flex;
  justify-content: space-between;

  .sign-up {
    margin: 250px 0 0 0;
  }

  img {
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .how {
    &-title {
      font-size: 22px;
      line-height: 1.18;
    }

    &-sub-title {
      font-size: 14px;
      line-height: 1.29;
    }

    &-tabs {
      justify-content: space-between;
      align-items: flex-start;

      &-desc {
        height: 100%;
      }
    }

    &-action {
      margin: 0;

      .step {
        font-size: 10px;
      }

      .title {
        font-size: 14px;
        line-height: 1.57;
      }
    }
  }

  .sign-up {
    width: 100%;
    border-radius: 0;

    .title {
      font-size: 22px;
      line-height: 1.18;
    }

    .content {
      font-size: 14px;
      line-height: 1.29;
    }
  }

  .build-cv {
    background-position: right bottom;
    background-size: 70%;

    .sign-up {
      margin-left: unset;
    }

    .skills {
      img {
        margin-left: unset;
      }
    }
  }

  .job-search {
    background: url("../../assets/circles-logo-alt.svg") right bottom no-repeat;
    background-size: 70%;
    flex-direction: column;

    .sign-up {
      margin: unset;
    }

    img {
      margin-left: 0;
    }
  }
}
</style>

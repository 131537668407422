<template>
  <div class="container me">
    <div class="row mt-5 d-flex align-items-center">
      <div class="col-md-6 text-center">
        <div class="me-empl">Employers are excited to</div>
        <div class="me-meet">
          <span>meet</span>
          <span>you</span>
        </div>

        <div class="me-meet-action">
          <div
            :class="{ active: firstActive }"
            @click.prevent.stop="switchTab(1)"
          >
            Full time roles
          </div>
          <div
            :class="{ active: secondActive }"
            @click.prevent.stop="switchTab(2)"
          >
            Get targeted interview invites right in your inbox
          </div>
          <div
            :class="{ active: thirdActive }"
            @click.prevent.stop="switchTab(3)"
          >
            Discover jobs, co-ops, internships, training programs, and more
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="horizontal-scroll">
          <div @mouseover="switchTab(1)" :class="{ 'col-12': true, 'me-ill': true, show: firstActive }">
            <div class="me-tab-action d-md-none">Find full time roles</div>
            <div class="me-q1">
              <div class="d-flex mt-n5">
                <img
                  src="../../assets/quote-left.svg"
                  class="mt-md-n5"
                  alt=""
                />
                <span class="me-quote mt-2"
                  >Opportunities are scattered and cannot be found easily</span
                >
                <img
                  src="../../assets/quote-right.svg"
                  class="mt-4 mt-md-5"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div @mouseover="switchTab(2)" :class="{ 'col-12': true, 'me-ill': true, show: secondActive }">
            <div class="me-tab-action d-md-none">Get targeted interview invites right in your inbox</div>
            <div class="me-q2">
              <div class="d-flex mt-n5">
                <img
                  src="../../assets/quote-left.svg"
                  class="mt-md-n5"
                  alt=""
                />
                <span class="me-quote mt-2"
                  >Not sure whether your CV reached an employer?</span
                >
                <img
                  src="../../assets/quote-right.svg"
                  class="mt-4 mt-md-5"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div @mouseover="switchTab(3)" :class="{ 'col-12': true, 'me-ill': true, show: thirdActive }">
            <div class="me-tab-action d-md-none">Discover jobs, co-ops, internships, training programs, and more</div>
            <div class="me-q3">
              <div class="d-flex mt-n5">
                <img
                  src="../../assets/quote-left.svg"
                  class="mt-md-n5"
                  alt=""
                />
                <span class="me-quote mt-2"
                  >Looking to work for an investment bank? or maybe a tech
                  startup?</span
                >
                <img
                  src="../../assets/quote-right.svg"
                  class="mt-3 mt-md-5"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="me-qd">
            KHIBRA brings you all career opportunities under one platform
          </div>
        </div>

        <div v-show="false"
          class="d-flex d-md-none align-items-center justify-content-center mt-3"
        >
          <div
            :class="{ bullet: true, active: firstActive }"
            @click.prevent.stop="switchTab(1)"
          ></div>
          <div
            :class="{ bullet: true, active: secondActive }"
            @click.prevent.stop="switchTab(2)"
          ></div>
          <div
            :class="{ bullet: true, active: thirdActive }"
            @click.prevent.stop="switchTab(3)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetEmployers",

  data() {
    return {
      currentTab: 1,
      totalTabs: 3,
    };
  },

  // mounted() {
  //   setInterval(() => {
  //     if (this.currentTab === this.totalTabs) {
  //       this.currentTab = 1;
  //     } else {
  //       this.currentTab = this.currentTab + 1;
  //     }
  //   }, 5000);
  // },

  computed: {
    firstActive() {
      return this.currentTab === 1;
    },

    secondActive() {
      return this.currentTab === 2;
    },

    thirdActive() {
      return this.currentTab === 3;
    },
  },

  methods: {
    switchTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.me {
  font-family: $default-font;

  &-empl {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.49;
    letter-spacing: normal;
    color: $dark;
  }

  &-meet {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    color: $dark;

    span:nth-child(1) {
      color: $blue;
      margin-right: 10px;
    }

    &-action {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      div {
        border-radius: 4px;
        box-shadow: 0 20px 30px 1px rgba(0, 0, 0, 0.02);
        border: solid 1px #a8c1ff;
        color: $dark;
        margin-top: 20px;
        width: 267px;
        height: auto;
        padding: 15px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;

        &.active {
          border: solid 1px #ebebf1;
          background-color: $blue;
          color: #ffffff;
          cursor: default;
        }
      }
    }
  }

  &-ill {
    height: 300px;
    display: none;

    &.show {
      display: block;
    }
  }

  &-qd {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
    width: 391px;
    margin-top: 30px;
  }

  &-quote {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $dark;
    margin: 0 5px 5px 10px;
  }

  &-q1,
  &-q2,
  &-q3 {
    height: 100%;
    background-size: contain;
    margin-top: 80px;
  }

  &-q1 {
    background: url("../../assets/find-full-time-jobs.svg") center no-repeat;

    .me-quote {
      width: 274px;
    }
  }

  &-q2 {
    background: url("../../assets/interviews.svg") center no-repeat;

    .me-quote {
      width: 229px;
    }
  }

  &-q3 {
    background: url("../../assets/discover.svg") center no-repeat;

    .me-quote {
      width: 300px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .me {
    &-empl {
      font-size: 14px;
      line-height: 1.5;
    }

    &-tab-action {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $blue;
      margin-top: 0.6rem;
      text-align: center;
      padding: 0 20px;
    }

    &-meet {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;

      &-action {
        display: none;
      }
    }

    &-ill {
      height: 250px;
      display: block;
      overflow-y: hidden;
    }

    &-qd {
      font-size: 18px;
      line-height: 1.33;
      width: 100%;
      margin-top: 10px;
      padding: 0 10px;
    }

    &-quote {
      font-size: 16px;
      line-height: 1.25;
      margin: 0 0 0 10px;
    }

    &-q1,
    &-q2,
    &-q3 {
      background-size: 90%;
      background-position: top;

      img {
        height: 14px;
        width: 16px;
      }

      .me-quote {
        width: 100%;
      }
    }
  }

  .bullet {
    width: 8px;
    height: 8px;
    margin: 0 8px 0 0;
    background-color: #b3b6bd;
    cursor: pointer;
    border-radius: 50%;

    &.active {
      cursor: default;
      background-color: $blue;
    }
  }
}
</style>

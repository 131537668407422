<template>
  <footer>
    <div class="container">
      <div class="footer">
        <div>
          <div class="row mb-3">
            <div class="col-md-12">
              <Logo class="mb-3" />

              <div class="row footer-links">
                <div class="col-6">
                  <RouterLink to="/employers">Employers</RouterLink>
                </div>
                <div class="col-6">
                  <RouterLink to="/about">About us</RouterLink>
                </div>
              </div>

              <div class="row footer-links">
                <div class="col-6">
                  <RouterLink to="/students">Students</RouterLink>
                </div>
                <div class="col-6">
                  <RouterLink to="/contact">Contact us</RouterLink>
                </div>
              </div>

              <div class="row footer-links">
                <div class="col-6">
                  <RouterLink to="/universities">Universities</RouterLink>
                </div>
                <div class="col-6">
                  <RouterLink to="/companies">Companies</RouterLink>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-social">
            <a href="https://www.facebook.com/getkhibra" target="_blank">
              <img
                src="../assets/05-icons-facebook.svg"
                class="img-fluid"
                alt="Facebook"
              />
            </a>

            <a
              href="https://www.linkedin.com/company/getkhibra"
              target="_blank"
            >
              <img
                src="../assets/05-icons-linked-in.svg"
                class="img-fluid"
                alt="Linkedin"
              />
            </a>

            <a href="https://www.instagram.com/get_khibra" target="_blank">
              <img
                src="../assets/05-icons-instagram.svg"
                class="img-fluid"
                alt="Instagram"
              />
            </a>

            <a href="https://twitter.com/GetKhibra" target="_blank">
              <img
                src="../assets/05-icons-twitter.svg"
                class="img-fluid"
                alt="Twitter"
              />
            </a>
          </div>

          <hr class="d-none d-md-block" />
        </div>

        <div>
          <div class="footer-call-main mb-2">
            Excited to join KHIBRA? Us, too!
          </div>
          <div class="footer-call-sub mb-3">
            Register your email and we’ll contact you as soon as the platform
            launches!
          </div>
          <div class="footer-form mb-3">
            <form
              :action="formPostUrl"
              method="post"
              name="mc-embedded-subscribe-form"
              id="subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <input
                type="email"
                name="EMAIL"
                class="form-control"
                placeholder="Email address"
                v-model="email"
              />

              <div class="text-center mt-2">
                <Button
                  @clicked="subscribe()"
                  type="big"
                  color="transparent"
                  class="btn-block"
                  >Subscribe</Button
                >
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row footer-legal">
        <div class="col-12 col-md-4">
          © {{ currYear }} Khibra. All Rights Reserved.
        </div>

        <!-- <div class="col-6 col-md-3">Privacy policy</div>
        <div class="col-6 col-md-3">Terms of service</div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import util from "@/scripts/util";
import Button from "./util/Button";
import Logo from "./util/Logo";

export default {
  name: "Footer",

  components: {
    Button,
    Logo,
  },

  data() {
    return {
      email: "",
    };
  },

  computed: {
    currYear() {
      return new Date().getFullYear();
    },

    formPostUrl() {
      return util.mailChimpPostUrl();
    },
  },

  methods: {
    subscribe() {
      if (this.email) {
        document.getElementById("subscribe-form").submit();

        setTimeout(()=>{
          this.email = "";
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

footer {
  background-image: linear-gradient(
    to bottom,
    rgba(243, 247, 255, 0.34),
    #f3f7ff
  );
  padding: 20px 0;

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    &-links {
      margin-bottom: 0.5rem;
      font-size: 14px;

      a {
        color: $dark;
      }

      div:nth-child(1) {
        font-weight: bold;
      }
    }

    &-legal {
      font-size: 12px;
      width: 60%;
      align-items: center;
    }

    &-social {
      img {
        margin-right: 15px;
      }

      a {
        text-decoration: none;
      }
    }

    &-call-main {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    &-call-sub {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      width: 75%;
    }

    &-form {
      width: 75%;

      Button {
        height: 50px;
      }
    }

    input[type="email"] {
      border: solid 1px #f1f1f5;
      height: 50px;

      &:focus {
        border-color: #ced4da;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
      }

      &::placeholder {
        color: rgba(65, 64, 77, 0.6);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  footer {
    padding: 20px;

    .footer {
      flex-direction: column;

      &-legal {
        width: 100%;

        div:nth-child(1) {
          order: 3;
          text-align: center;
          margin-top: 10px;
        }

        div:nth-child(2),
        div:nth-child(3) {
          text-align: center;
        }
      }

      &-social {
        display: flex;
        justify-content: space-around;
        margin-bottom: 3rem;

        img {
          margin-right: unset;
        }
      }

      &-call-main {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
      }

      &-call-sub {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        width: 100%;
      }

      &-form {
        width: 100%;

        Button {
          height: 40px;
        }
      }

      input[type="email"] {
        height: 40px;
      }
    }
  }
}
</style>

<template>
  <div class="student">
    <div class="student-menu">
      <div class="active">Overview</div>
      <div @click.prevent.stop="scrollToElem('how-it-works')">How it works</div>
      <div @click.prevent.stop="scrollToElem('uni-partners')">
        Employer partners
      </div>
    </div>

    <div class="student-banner">
      <div class="container">
        <div class="row d-md-flex justify-content-md-between">
          <div class="col-md-6 student-banner-left mt-5 pt-5">
            <div class="student-kh-logo">
              <img
                src="../../assets/logo-symbol.png"
                srcset="
                  ../../assets/logo-symbol@2x.png 2x,
                  ../../assets/logo-symbol@3x.png 3x
                "
                class="img-fluid"
              />

              <div class="student-lbl mt-3">Khibra</div>
            </div>

            <div class="student-ftr">Find jobs by top rated employers</div>

            <div class="student-rec">
              Recommended by universities across the Middle East
            </div>

            <Button type="big" class="mt-4 btn-block" @clicked="goToApp()">Get Started</Button>
          </div>

          <div class="col-md-6 student-banner-right">
            <div class="job-recommend-card d-none d-md-block">
              <div class="heading">
                <img
                  src="../../assets/job-recommendation-icon.png"
                  srcset="
                    ../../assets/job-recommendation-icon@2x.png 2x,
                    ../../assets/job-recommendation-icon@3x.png 3x
                  "
                  class="img-fluid"
                  alt=""
                />

                <div class="mt-2">Job Recommendations</div>
              </div>
              <div class="job-title">Junior Software Engineer</div>
              <div class="tags">
                <div>
                  <img src="../../assets/grad-icon.svg" alt="Graduation icon" />
                  <span>Fresh Graduate</span>
                </div>
                <div>
                  <img src="../../assets/loc-icon.svg" alt="Location icon" />
                  <span>Dubai</span>
                </div>
              </div>
            </div>

            <img
              src="../../assets/jr.png"
              srcset="../../assets/jr@2x.png 2x, ../../assets/jr@3x.png 3x"
              class="img-fluid student-jr d-md-none"
              alt=""
            />

            <div class="career-tips-card d-none d-md-block">
              <div class="heading">
                <img
                  src="../../assets/career-tips-icon.png"
                  srcset="
                    ../../assets/career-tips-icon@2x.png 2x,
                    ../../assets/career-tips-icon@3x.png 3x
                  "
                  class="img-fluid"
                  alt=""
                />

                <div>Career Tips</div>
              </div>
              <div class="timespan">5 minutes read</div>
              <div class="content">
                How to adjust to the new normal of remote work
              </div>
              <div class="profile">
                <img
                  src="../../assets/profile-icon.png"
                  srcset="
                    ../../assets/profile-icon@2x.png 2x,
                    ../../assets/profile-icon@3x.png 3x
                  "
                  class="img-fluid"
                  alt=""
                />
                <div>
                  <div class="name">Alexa Richardson</div>
                  <div class="role">Professor in Kuwait RA</div>
                </div>
                <img
                  src="../../assets/arrow-right.png"
                  srcset="
                    ../../assets/arrow-right@2x.png 2x,
                    ../../assets/arrow-right@3x.png 3x
                  "
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>

            <img
              src="../../assets/ct.png"
              srcset="../../assets/ct@2x.png 2x, ../../assets/ct@3x.png 3x"
              class="img-fluid student-ct d-md-none"
              alt=""
            />

            <div class="message-card d-none d-md-block">
              <div class="icon">
                <img src="../../assets/company-icon.svg" alt="" />
              </div>
              <div class="greeting">Hi Ahmed,</div>
              <div class="content">
                We are pleased to let you know we have shortlisted your
                application
              </div>
              <div class="event-time">
                <div>Tue, April 16th , 2020</div>
                <div>10:00 am</div>
              </div>
            </div>

            <img
              src="../../assets/employer-messaging.png"
              srcset="
                ../../assets/employer-messaging@2x.png 2x,
                ../../assets/employer-messaging@3x.png 3x
              "
              class="img-fluid student-msg d-md-none"
              alt=""
            />

            <div class="tick-icon d-none d-md-block">
              <img src="../../assets/tick-icon.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <MeetEmployers class="pt-10" />

    <UniPartners id="uni-partners" class="mt-5 mt-md-10" />

    <HowItWorks id="how-it-works" class="pt-5 pt-md-10" />

    <Internship class="mt-5" />
  </div>
</template>

<script>
import util from "@/scripts/util";
import Button from "@/components/util/Button";
import HowItWorks from "@/components/students/HowItWorks";
import Internship from "@/components/students/Internship";
import MeetEmployers from "@/components/students/MeetEmployers";
import UniPartners from "@/components/UniPartners";

export default {
  name: "Students",

  components: {
    Button,
    HowItWorks,
    Internship,
    MeetEmployers,
    UniPartners,
  },

  methods: {
    scrollToElem(elemId) {
      util.scrollToElement(elemId);
    },

    goToApp() {
      window.open(`${process.env.VUE_APP_URL}/register`, "_blank");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.student {
  font-family: $default-font;

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef3fe;
    width: 100%;
    padding: 10px 0;

    div {
      margin-right: 15px;
      margin-left: 15px;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }
    }
  }

  &-banner {
    background-color: #f3f7ff;

    &-right {
      height: 578px;
      background: url("../../assets/person.png") right bottom no-repeat,
        url("../../assets/circle-lines.svg") right bottom no-repeat;
      background-size: contain, 100%;
    }

    &-left {
      Button {
        height: 54px;
        width: 349px;
      }
    }
  }

  &-kh-logo {
    display: flex;
    align-items: center;
  }

  &-lbl {
    margin-left: 0.8rem;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: $blue;
  }

  &-ftr {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: $dark;
  }

  &-rec {
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $dark;
  }

  .job-recommend-card {
    position: absolute;
    top: 45%;
    left: -10%;
    width: 228px;
    height: 158px;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(27px);
    backdrop-filter: blur(27px);
    box-shadow: 0 16px 40px 2px rgba(0, 0, 0, 0.19);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: -5px;
      color: $blue;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      img {
        margin-right: 10px;
      }
    }

    .job-title {
      opacity: 0.9;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $dark;
      margin-top: 20px;
    }

    .tags {
      display: flex;
      align-items: center;
      margin-top: 15px;

      div {
        margin-right: 10px;
        border-radius: 4px;
        background-color: rgba(0, 74, 255, 0.04);
        padding: 0 10px 0 5px;
        height: 27px;
      }

      span {
        font-size: 8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: $dark;
        margin-left: 5px;
      }
    }
  }

  .career-tips-card {
    position: absolute;
    right: 0;
    top: 72%;
    width: 234px;
    height: 216px;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(27px);
    backdrop-filter: blur(27px);
    box-shadow: 0 16px 40px 2px rgba(0, 0, 0, 0.19);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: -5px;
      color: $blue;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      img {
        margin-right: 10px;
      }
    }

    .timespan {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #93939f;
      margin-top: 25px;
    }

    .content {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $dark;
      margin-top: 20px;
    }

    .profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      margin-top: 10px;

      .name {
        color: #171726;
      }

      .role {
        color: #93939f;
      }
    }
  }

  .message-card {
    position: absolute;
    bottom: -18%;
    left: -20%;
    width: 256px;
    height: 162px;
    padding: 39px 32px 33px;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(27px);
    backdrop-filter: blur(27px);
    box-shadow: 0 16px 40px 2px rgba(0, 0, 0, 0.19);
    background-color: rgba(255, 255, 255, 0.8);

    .icon {
      margin-top: -70px;
    }

    .greeting,
    .content {
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    .greeting {
      font-weight: bold;
      margin-top: 20px;
    }

    .content {
      margin-top: 10px;
    }

    .event-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0.69;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-top: 20px;
    }
  }

  .tick-icon {
    position: absolute;
    bottom: 10%;
    left: -30%;
  }
}

@media only screen and (max-width: 768px) {
  .student {
    &-menu {
      display: none;
    }

    &-banner {
      &-right {
        height: 400px;
        background: url("../../assets/person.png") right bottom no-repeat;
        background-position: center bottom;
        background-size: 90%;
      }

      &-left {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      Button {
        height: 40px;
      }
    }

    &-lbl {
      font-size: 24px;
      line-height: 1.27;
    }

    &-ftr {
      font-size: 18px;
      line-height: 1.5;
      margin-top: 10px;
    }

    &-rec {
      margin-top: 5px;
      font-size: 14px;
      line-height: 1.5;
    }

    &-jr {
      position: absolute;
      top: 50%;
      left: 0;
      object-fit: contain;
    }

    &-ct {
      position: absolute;
      right: 0;
      top: 85%;
      object-fit: contain;
    }

    &-msg {
      position: absolute;
      bottom: -40%;
      left: 4%;
      object-fit: contain;
    }
  }
}
</style>

<template>
  <div class="employer">
    <div class="employer-menu">
      <div class="active">Overview</div>
      <div @click.prevent.stop="scrollToElem('prod-feat')">
        Product Features
      </div>
      <div @click.prevent.stop="scrollToElem('career-fairs')">Career Fairs</div>
    </div>

    <div class="employer-banner">
      <div class="container">
        <div class="row pt-5">
          <div class="col-md-4 offset-md-4">
            <div class="fut-lead">Future leaders are</div>
            <div class="fut-lead">one step away</div>
            <div class="fut-lead">
              Access a large pool of young talent from a variety of local and
              international universities
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 mt-md-n5 search-img">
            <img
              src="../../assets/search-cand.png"
              srcset="
                ../../assets/search-cand@2x.png 2x,
                ../../assets/search-cand@3x.png 3x
              "
              class="img-fluid"
            />
          </div>

          <div class="col-md-4 reg-form">
            <RegForm activeUserType="employer" />
          </div>

          <div class="col-md-4 mt-md-n5 d-none d-md-block">
            <div>
              <img
                src="../../assets/profilename.png"
                srcset="
                  ../../assets/profilename@2x.png 2x,
                  ../../assets/profilename@3x.png 3x
                "
                class="img-fluid"
              />
            </div>
            <div class="text-right mt-n4">
              <img
                src="../../assets/keyhighlights.png"
                srcset="
                  ../../assets/keyhighlights@2x.png 2x,
                  ../../assets/keyhighlights@3x.png 3x
                "
                class="img-fluid"
              />
            </div>
            <div class="mt-n5">
              <img
                src="../../assets/talent.png"
                srcset="
                  ../../assets/talent@2x.png 2x,
                  ../../assets/talent@3x.png 3x
                "
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="prod-feat">
      <div class="prod-feat">
        <div class="title">Product Features</div>

        <div class="content row">
          <div class="col-md-4">
            <div class="font-weight-bold mb-2 mb-md-3">
              Employer page and branded jobs
            </div>
            <div class="mb-5 mb-md-0 content-body">
              Allow candidates to review your organization's information,
              opportunities, and latest developments
            </div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold mb-2 mb-md-3">Brand campaigns</div>
            <div class="mb-5 mb-md-0 content-body">
              Share employer news and updates to keep your talent prospects
              engaged
            </div>
          </div>
          <div class="col-md-4">
            <div class="font-weight-bold mb-2 mb-md-3">
              Event promotion and management
            </div>
            <div class="mb-5 mb-md-0 content-body">
              Join virtual university career fairs or launch off-season targeted
              virtual info sessions
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container candidates mb-5">
      <div class="d-md-none mb-3">
        <div class="col-12">
          <KhibraHO />
        </div>
      </div>

      <div class="horizontal-scroll hidden-scrollbar">
        <div class="col-12 col-md-3 mb-3 mb-md-0 mt-5 d-none d-md-block">
          <KhibraHO />
        </div>

        <div class="col-12 col-md-3 mb-3 mb-md-0">
          <div class="candidates-card search">
            <img
              src="../../assets/05-icons-targeted-segments-search.png"
              srcset="
                ../../assets/05-icons-targeted-segments-search@2x.png 2x,
                ../../assets/05-icons-targeted-segments-search@3x.png 3x
              "
              alt=""
              class="img-fluid"
            />

            <div class="text">
              <div class="title">Targeted Segments Search</div>
              <div class="content">
                Find the background that suits your job opening in seconds
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 mb-3 mb-md-0">
          <div class="candidates-card campaign">
            <div class="text">
              <div class="title mt-4 w-50">Custom Message Campaign</div>
              <div class="content">
                Engage your brand with future leaders, no matter where they are
              </div>
            </div>
            <div>
              <img
                src="../../assets/05-icons-targeted-segments-campaign.png"
                srcset="
                  ../../assets/05-icons-targeted-segments-campaign@2x.png 2x,
                  ../../assets/05-icons-targeted-segments-campaign@3x.png 3x
                "
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 mb-3 mb-md-0">
          <div class="candidates-card journey">
            <div class="text">
              <div class="title mt-4 w-50">Candidate Journey</div>
              <div class="content">Track applicants’ engagement history</div>
            </div>
            <div>
              <img
                src="../../assets/05-icons-targeted-segments-journey.png"
                srcset="
                  ../../assets/05-icons-targeted-segments-journey@2x.png 2x,
                  ../../assets/05-icons-targeted-segments-journey@3x.png 3x
                "
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container hire mb-3 mb-md-5">
      <div class="hire-text">
        Hire based on <span>data</span> not on gut feelings
      </div>

      <div class="hire-bg">
        <img
          src="../../assets/academic-statistics.png"
          srcset="
            ../../assets/academic-statistics@2x.png 2x,
            ../../assets/academic-statistics@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />

        <div class="row">
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="title">Screening</div>
            <div class="content">
              Screen and benchmark candidates using structured data
            </div>
          </div>

          <div class="col-md-4 mb-4 mb-md-0">
            <div class="title">Assessment</div>
            <div class="content">
              Leverage KHIBRA's integrated assessments to shortlist applicants
            </div>
          </div>

          <div class="col-md-4 mb-4 mb-md-0">
            <div class="title">Insights</div>
            <div class="content">
              Generate insights from your organization's campaign
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5" id="career-fairs">
      <div class="row">
        <div class="col-md-6">
          <div class="virtual">
            Get to know universities and students through
          </div>

          <div class="virtual">virtual career fairs</div>

          <div class="virtual">
            or launch off-season targeted virtual info sessions
          </div>

          <VerticalSteps class="mt-4" :steps="steps" />
        </div>

        <div class="col-md-6 virtual-form">
          <div>
            <RegForm activeUserType="employer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/scripts/util";
import KhibraHO from "@/components/util/KhibraHO";
import RegForm from "@/components/util/RegForm";
import VerticalSteps from "@/components/util/VerticalSteps";

export default {
  name: "Employers",

  components: {
    KhibraHO,
    RegForm,
    VerticalSteps,
  },

  data() {
    return {
      steps: [
        "Register to attend a virtual career fair",
        "Schedule your time slots",
        "Engage with future leaders and attract them to your brand",
      ],
    };
  },

  methods: {
    scrollToElem(elemId) {
      util.scrollToElement(elemId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.employer {
  font-family: $default-font;
  margin-bottom: 7rem;

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef3fe;
    width: 100%;
    padding: 10px 0;

    div {
      margin-right: 15px;
      margin-left: 15px;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }
    }
  }

  &-banner {
    background-color: #f3f7ff;
    background-image: url("../../assets/circles.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 578px;

    img {
      -webkit-backdrop-filter: blur(27px);
      backdrop-filter: blur(27px);
    }

    .fut-lead {
      &:nth-child(1) {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: $dark;
      }

      &:nth-child(2) {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: $blue;
      }

      &:nth-child(3) {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        color: $dark;
        margin-top: 10px;
      }
    }
  }
}

.prod-feat {
  margin-top: 15rem;
  margin-bottom: 5rem;
  padding: 67px 72px 72px 50px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  box-shadow: 0 16px 40px 2px rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.8);

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: $dark;
    margin-bottom: 1.5rem;
  }

  .content {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
  }
}

.candidates {
  &-card {
    width: 236px;
    height: 394px;
    padding: 8px;
    border: solid 1px $blue;
    border-radius: 4px;
    box-shadow: 0 40px 30px 2px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    position: relative;

    .title {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: $dark;
      width: 75%;
    }

    .content {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $dark;
      margin-top: 1rem;
      opacity: 0.8;
      width: 75%;
    }

    &.search {
      .text {
        position: absolute;
        bottom: 5%;
        left: 10%;
      }

      img {
        position: absolute;
        left: -20%;
        top: 5%;
      }
    }

    &.campaign {
      .text {
        position: absolute;
        left: 10%;
      }

      img {
        position: absolute;
        right: 0;
        bottom: 5%;
      }
    }

    &.journey {
      .text {
        position: absolute;
        left: 10%;
      }

      img {
        position: absolute;
        right: 0;
        bottom: -5%;
      }
    }
  }
}

.hire {
  margin-top: 15rem;
  display: flex;

  &-text {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $dark;
    margin-top: 7rem;

    span {
      color: $blue;

      &::after {
        content: ",\a";
        white-space: pre;
        color: $dark;
      }
    }
  }

  &-bg {
    width: 75%;
    margin-left: -7rem;
    margin-right: -10rem;
    padding: 442px 48px 45px;
    border-radius: 8px;
    box-shadow: 0 20px 30px 1px rgba(0, 0, 0, 0.02);
    background-color: rgba(0, 74, 255, 0.04);
    position: relative;

    img {
      position: absolute;
      top: -20%;
      left: 15%;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: $dark;
      text-transform: capitalize;
    }

    .content {
      opacity: 0.8;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $dark;
      margin-top: 10px;
    }
  }
}

.virtual {
  &:nth-child(1),
  &:nth-child(3) {
    font-family: $default-font;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $dark;
  }

  &:nth-child(2) {
    font-family: $default-font;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $blue;
  }

  &-form {
    div {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .employer {
    &-menu {
      display: none;
    }

    &-banner {
      height: 100%;

      img {
        -webkit-backdrop-filter: unset;
        backdrop-filter: unset;
      }

      .fut-lead {
        &:nth-child(1) {
          font-size: 16px;
          line-height: 1.5;
        }

        &:nth-child(2) {
          font-size: 18px;
          line-height: 1.5;
        }

        &:nth-child(3) {
          font-size: 14px;
          line-height: 1.5;
        }
      }

      .search-img {
        display: flex;
        justify-content: center;
      }

      .reg-form {
        padding: 0;
      }
    }
  }

  .prod-feat {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 40px 21px 40px 22px;
    border-radius: 0;
    text-align: center;

    .content-body {
      font-size: 14px;
    }
  }

  .candidates {
    &-card {
      width: 100%;
      height: 334px;
      box-shadow: unset;

      .title {
        font-size: 16px;
        line-height: 1.5;
      }

      .content {
        font-size: 14px;
      }

      &.search {
        .text {
          bottom: 10%;
        }

        img {
          left: -15%;
        }
      }
    }
  }

  .hire {
    margin-top: 5rem;
    flex-direction: column;
    padding: 0;

    &-text {
      font-size: 30px;
      line-height: 1.27;
      margin-top: unset;
      text-align: center;
      margin-bottom: 100px;
    }

    &-bg {
      width: 100%;
      margin-left: unset;
      margin-right: unset;
      padding: 250px 48px 45px;
      border-radius: 0;

      img {
        top: -10%;
        left: 0;
      }

      .title {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
      }

      .content {
        opacity: 1;
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
      }
    }
  }

  .virtual {
    text-align: center;
    &:nth-child(1),
    &:nth-child(3) {
      font-size: 16px;
      line-height: 1.5;
    }

    &:nth-child(2) {
      font-size: 30px;
      line-height: 1.27;
    }

    &-form {
      padding: 0;

      div {
        width: 100%;
      }
    }
  }
}
</style>

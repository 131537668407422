<template>
  <button :disabled="disable" @click.prevent.stop="clicked()" class="btn" :class="[type, color]">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disable: Boolean,
    type: {
      type: String,
      default: "small",
    },
    color: {
      type: String,
      default: "default",
    },
  },

  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

button {
  border-radius: 4px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  &.default {
    border: solid 0.5px $blue;
    background-color: $blue;
    color: white;

    &:hover:not(:disabled) {
      color: $blue;
      background-color: white;
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
      color: $blue;
      background-color: $grey;
    }
  }

  &.dark {
    border: solid 0.5px $dark;
    background-color: $dark;
    color: white;

    &:hover:not(:disabled) {
      color: $dark;
      background-color: white;
      opacity: 0.8;
    }

    &:disabled {
      color: $dark;
      background-color: $grey;
    }
  }

  &.white {
    background-color: white;
    border: solid 0.5px $blue;
    color: $blue;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    &:disabled {
      background-color: $grey;
      opacity: 0.3;
    }
  }

  &.transparent {
    background-color: transparent;
    border: solid 0.5px $blue;
    color: $blue;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    &:disabled {
      background-color: $grey;
      opacity: 0.3;
    }
  }

  &.big {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    height: 40px;
  }

  &.small {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  button {
    font-size: 12px;
  }
}
</style>

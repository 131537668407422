<template>
  <div>
    <div v-if="isSentMode" class="sent">
      <div class="row d-none d-md-block">
        <div class="col-12 back" @click.prevent.stop="backToFormMode()">
          <span>&lt;</span> <span class="ml-4">Send an email</span>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="sent-card">
            <div class="envelope">
              <img
                src="../../assets/email-sent.png"
                srcset="
                  ../../assets/email-sent@2x.png 2x,
                  ../../assets/email-sent@3x.png 3x
                "
                alt=""
              />
            </div>

            <div class="msg">
              <div>
                <div class="title">Your message was sent successfully</div>

                <div class="sub">
                  We will get back to you as soon as possible
                </div>
              </div>

              <div class="d-none d-md-block">
                <img
                  src="../../assets/tick.png"
                  srcset="
                    ../../assets/tick@2x.png 2x,
                    ../../assets/tick@3x.png 3x
                  "
                  alt=""
                />
              </div>
            </div>

            <Button type="big" class="mt-3" @clicked="backToFormMode()"
              >Send another message</Button
            >
          </div>
        </div>
      </div>
    </div>

    <div v-else class="form">
      <div class="form-row">
        <div class="col-12 title">Help us get to know you</div>
      </div>

      <form
        :action="formPostUrl"
        method="post"
        :id="formId"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div class="form-row mt-3" v-if="formErrMsg">
          <div class="col-12 small text-center text-danger">{{ formErrMsg }}</div>
        </div>

        <div class="form-row mt-3">
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              placeholder="First name"
              name="FNAME"
              v-model="firstName"
            />
          </div>
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              placeholder="Last name"
              name="LNAME"
              v-model="lastName"
            />
          </div>
        </div>

        <div class="form-row mt-3">
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              placeholder="Company"
              name="ORGNAME"
              v-model="company"
            />
          </div>
          <div class="col-6">
            <input
              type="email"
              class="form-control"
              placeholder="Work email"
              name="EMAIL"
              v-model="email"
            />
          </div>
        </div>

        <div class="form-row mt-3">
          <div class="col-12">
            <textarea
              class="form-control"
              cols="30"
              rows="10"
              placeholder="Your message"
              name="MESSAGE"
              v-model="message"
            ></textarea>
          </div>
        </div>

        <div class="form-row mt-3">
          <div class="col-12">
            <Button @clicked="submitForm()" class="btn-block" type="big"
              >Get in touch</Button
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import util from "@/scripts/util";
import Button from "@/components/util/Button";

export default {
  name: "ContactForm",

  components: {
    Button,
  },

  props: {
    formId: {
      type: String,
      default: "contact-form"
    }
  },

  data() {
    return {
      mode: "form",
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      message: "",
      formErrMsg: "",
    };
  },

  computed: {
    isSentMode() {
      return this.mode === "sent";
    },

    formPostUrl() {
      return util.mailChimpPostUrl();
    },

    formValid() {
      return (
        this.firstName &&
        this.lastName &&
        this.company &&
        this.email &&
        this.message
      );
    },
  },

  methods: {
    backToFormMode() {
      this.mode = "form";
    },

    submitForm() {
      if (this.formValid) {
        document.getElementById(this.formId).submit();

        setTimeout(() => {
          this.clearFields();
          this.mode = "sent";
        }, 3000);
      } else {
        this.formErrMsg = "All fields are required";
      }
    },

    clearFields() {
      this.firstName = "";
      this.lastName = "";
      this.company = "";
      this.email = "";
      this.message = "";
      this.formErrMsg = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.form {
  padding: 48px;
  border-radius: 4px;
  box-shadow: 0 40px 30px 2px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: $dark;
  }

  input {
    height: 55px;
  }

  input,
  textarea {
    border: solid 1px #f1f1f5;
    background-color: #fafafb;

    &::placeholder {
      opacity: 0.6;
    }

    &:focus {
      border: 1px solid $blue;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  textarea {
    resize: none;
  }

  Button {
    height: 60px;
  }
}

.sent {
  margin-top: 50px;

  .back {
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &-card {
    margin: 25px 30px 0 0;
    padding: 50px 30px;
    border-radius: 4px;
    box-shadow: 0 40px 30px 2px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    // position: relative;

    .envelope {
      margin-left: -60px;
      padding-top: 50px;
    }

    .msg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $dark;
      margin-top: 20px;

      .title {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
      }

      .sub {
        margin-top: 10px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .form {
    padding: 20px;
    border-radius: 0;

    .title {
      font-size: 22px;
      line-height: 1.18;
    }

    input {
      height: 40px;
    }

    Button {
      height: 40px;
    }
  }

  .sent {
    margin-top: 30px;

    &-card {
      margin: 0;
      border-radius: 0;
      text-align: center;

      .envelope {
        margin-left: 0;
        padding-top: 0;
        display: flex;
        justify-content: center;

        img {
          width: 107px;
          height: 82px;
        }
      }

      .msg {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .title {
          font-size: 18px;
          line-height: 1.5;
          font-weight: bold;
        }

        .sub {
          margin-top: 0;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>

<template>
  <div class="university">
    <div class="university-menu">
      <div class="active">Overview</div>
      <div @click.prevent.stop="scrollToElem('benefits')">Benefits</div>
      <div @click.prevent.stop="scrollToElem('partners')">Partners</div>
      <div @click.prevent.stop="scrollToElem('data-protection')">
        Data protection
      </div>
    </div>

    <div class="university-banner">
      <div class="container">
        <div class="university-banner-content">
          <div class="university-banner-content-main">
            <div class="help-connect">
              Help connect your students with job markets, hassle-free
            </div>

            <div class="dc">
              <div>Expose students to various jobs under a single platform</div>
              <div>Build lasting relationships with employers</div>
            </div>

            <div class="gi">
              <div>Gain insights using data</div>
            </div>
          </div>

          <div class="university-banner-content-form">
            <RegForm bg="white" activeUserType="university" />
          </div>
        </div>
      </div>
    </div>

    <div id="benefits" class="container mt-5">
      <div class="university-benefits">
        <div class="row top">
          <div class="col-md-4 top-left-card">
            <div class="shadow">
              <div class="main">
                <div>
                  <img
                    src="../../assets/logo-symbol.png"
                    srcset="
                      ../../assets/logo-symbol@2x.png 2x,
                      ../../assets/logo-symbol@3x.png 3x
                    "
                    class="img-fluid"
                    alt=""
                  />
                  <div class="khibra">Khibra</div>
                </div>

                <div class="inner">University <b>Benefits</b></div>
              </div>
            </div>
          </div>

          <div class="col-md-4 top-card">
            <img
              src="../../assets/05-icons-application.png"
              srcset="
                ../../assets/05-icons-application@2x.png 2x,
                ../../assets/05-icons-application@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
            <div class="inner">
              Recruiters post jobs for university students under a single
              platform
            </div>
          </div>

          <div class="col-md-4 top-card">
            <img
              src="../../assets/05-icons-candidate.png"
              srcset="
                ../../assets/05-icons-candidate@2x.png 2x,
                ../../assets/05-icons-candidate@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
            <div class="inner">
              Identify and promote your top students and graduates to leading
              employers
            </div>
          </div>
        </div>

        <div class="row bottom">
          <div class="col-md-4 bottom-card">
            <img
              src="../../assets/05-icons-track.png"
              srcset="
                ../../assets/05-icons-track@2x.png 2x,
                ../../assets/05-icons-track@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
            <div class="inner">
              Track student check-ins and registrations at university events
            </div>
          </div>

          <div class="col-md-4 bottom-card">
            <img
              src="../../assets/05-icons-email-campaign.png"
              srcset="
                ../../assets/05-icons-email-campaign@2x.png 2x,
                ../../assets/05-icons-email-campaign@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
            <div class="inner">
              Communicate with a vast number of employers directly
            </div>
          </div>

          <div class="col-md-4 bottom-card">
            <img
              src="../../assets/05-icons-pie.png"
              srcset="
                ../../assets/05-icons-pie@2x.png 2x,
                ../../assets/05-icons-pie@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
            <div class="inner">
              Track and benchmark student career journey via an exclusive
              dedicated dashboard
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="partners" class="container university-partners">
      <div class="university-partners-bg">
        <div>
          <div class="content">
            <div class="title">Partners</div>
            <div class="body">
              Collaborating with universities to enhance the career prospects of
              their graduates
            </div>
          </div>
        </div>

        <div class="images">
          <img
            src="../../assets/aub.png"
            srcset="../../assets/aub@2x.png 2x, ../../assets/aub@3x.png 3x"
            class="img-fluid"
            alt=""
          />
          <img
            src="../../assets/auk.png"
            srcset="../../assets/auk@2x.png 2x, ../../assets/auk@3x.png 3x"
            class="img-fluid"
            alt=""
          />
          <img
            src="../../assets/bibf.png"
            srcset="../../assets/bibf@2x.png 2x, ../../assets/bibf@3x.png 3x"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="row images-sm d-md-none mt-5">
          <div class="col-4">
            <img
              src="../../assets/ack-logo.png"
              srcset="
                ../../assets/ack-logo@2x.png 2x,
                ../../assets/ack-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-4">
            <img
              src="../../assets/aub-logo.png"
              srcset="
                ../../assets/aub-logo@2x.png 2x,
                ../../assets/aub-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-4">
            <img
              src="../../assets/k-tech-logo.png"
              srcset="
                ../../assets/k-tech-logo@2x.png 2x,
                ../../assets/k-tech-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-4">
            <img
              src="../../assets/auk-logo.png"
              srcset="
                ../../assets/auk-logo@2x.png 2x,
                ../../assets/auk-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-4">
            <img
              src="../../assets/bibf-logo.png"
              srcset="
                ../../assets/bibf-logo@2x.png 2x,
                ../../assets/bibf-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-4">
            <img
              src="../../assets/kcst-logo.png"
              srcset="
                ../../assets/kcst-logo@2x.png 2x,
                ../../assets/kcst-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-md-5 mt-3">
      <div class="university-launch pt-5">
        <div class="title">
          Launch in-person, virtual, and hybrid career fairs!
        </div>

        <div class="sub-title">
          Use KHIBRA to plan for the events and track engagements of both
          employers and students
        </div>

        <div class="row pt-md-5 mt-5">
          <div class="col-md-5">
            <VerticalSteps :steps="steps" />
          </div>

          <div class="col-md-7 mt-5">
            <img
              src="../../assets/circles-blue.png"
              srcset="
                ../../assets/circles-blue@2x.png 2x,
                ../../assets/circles-blue@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div id="data-protection" class="university-protection">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="security-card">
              <div class="lock">
                <img
                  src="../../assets/05-icons-security.png"
                  srcset="
                    ../../assets/05-icons-security@2x.png 2x,
                    ../../assets/05-icons-security@3x.png 3x
                  "
                  class="img-fluid"
                  alt=""
                />
              </div>

              <div class="main">Your security is our success</div>

              <div class="sub">
                We make sure that your data is protected and is controlled only
                by account holders
              </div>

              <div class="compliance d-none">
                <img
                  src="../../assets/fepra.png"
                  srcset="
                    ../../assets/fepra@2x.png 2x,
                    ../../assets/fepra@3x.png 3x
                  "
                  class="img-fluid"
                  alt=""
                />
                <div>FERPA compliant</div>
              </div>
            </div>
          </div>

          <div class="university-protection-form col-md-5">
            <div>
              <RegForm activeUserType="university" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/scripts/util";
import RegForm from "@/components/util/RegForm";
import VerticalSteps from "@/components/util/VerticalSteps";

export default {
  name: "Universities",

  components: {
    RegForm,
    VerticalSteps,
  },

  data() {
    return {
      steps: [
        "Schedule your career fair and invite employers and students to register",
        "Launch events to get students and regional employers engage and share opportunities",
        "Track engagements and generate insights from your events",
      ],
    };
  },

  methods: {
    scrollToElem(elemId) {
      util.scrollToElement(elemId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.university {
  font-family: $default-font;
  margin-bottom: 7rem;

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef3fe;
    width: 100%;
    padding: 10px 0;

    div {
      margin-right: 15px;
      margin-left: 15px;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }
    }
  }

  &-banner {
    background-color: $blue;
    background-image: url("../../assets/circles.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 600px;
    padding: 112px 150px;

    &-content {
      display: flex;
      justify-content: space-between;
      padding-top: 3rem;

      &-main {
        width: 50%;
        margin-right: 3rem;
      }

      &-form {
        width: 50%;
        margin-left: 4rem;
      }
    }

    .help-connect {
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #ffffff;
    }

    .dc {
      display: flex;
      align-items: flex-end;
      margin-top: 2rem;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        width: 301px;
        height: 128px;
        border-radius: 8px;
        box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        font-size: 20px;
        text-align: center;
        margin-right: 30px;
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        width: 271px;
        height: 160px;
        border-radius: 8px;
        box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        font-size: 20px;
        text-align: center;
      }
    }

    .gi {
      margin-top: 2.5rem;

      div {
        width: 333px;
        height: 96px;
        margin-left: 50px;
        padding: 32px;
        border-radius: 8px;
        box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        font-size: 20px;
        text-align: center;
      }
    }
  }

  &-benefits {
    .top {
      &-left-card {
        .shadow {
          width: 75%;
          padding: 8px;
          border-radius: 4px;
          -webkit-backdrop-filter: blur(27px);
          backdrop-filter: blur(27px);
          box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
          border: solid 1px $blue;
          margin-top: -100px;

          .main {
            padding: 52px 31px 51px 24px;
            border-radius: 4px;
            -webkit-backdrop-filter: blur(27px);
            backdrop-filter: blur(27px);
            box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
            background-color: rgba(255, 255, 255, 0.8);

            .khibra {
              margin-top: 20px;
              font-family: $default-font;
              font-size: 30px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.07;
              letter-spacing: normal;
              color: $blue;
            }

            .inner {
              margin: 29px 0 0;
              font-size: 28px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.32;
              letter-spacing: normal;
              color: $dark;
            }
          }
        }
      }

      &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-left: 1px solid $grey;
        border-bottom: 1px solid $grey;

        img {
          margin-bottom: 15px;
          object-fit: contain;
        }

        .inner {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $dark;
        }
      }
    }

    .bottom {
      &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 50px;

        img {
          margin-bottom: 15px;
          object-fit: contain;
        }

        .inner {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $dark;
          font-family: $default-font;
        }
      }

      &-card:nth-child(2),
      &-card:nth-child(3) {
        border-left: 1px solid $grey;
      }
    }
  }

  &-partners {
    margin-top: 10rem;

    &-bg {
      height: 337px;
      padding: 0 72px;
      border-radius: 4px;
      box-shadow: 0 20px 30px 1px rgba(0, 0, 0, 0.02);
      background-color: rgba(0, 74, 255, 0.04);
      display: flex;
      justify-content: space-between;
      color: $dark;
      position: relative;

      .content {
        position: absolute;
        top: 35%;
        width: 50%;

        .title {
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
        }

        .body {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          margin-top: 15px;
        }
      }

      .images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img:nth-of-type(even) {
          margin-left: 200px;
        }
      }
    }
  }

  &-launch {
    .title {
      margin-bottom: 20px;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: $blue;
    }

    .sub-title {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: $dark;
    }
  }

  &-protection {
    margin-top: -100px;

    &-form {
      div {
        width: 75%;
      }
    }

    .security {
      &-card {
        position: relative;
        padding: 96px 120px 48px 48px;
        border-radius: 4px;
        -webkit-backdrop-filter: blur(27px);
        backdrop-filter: blur(27px);
        box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
        background-color: rgba(255, 255, 255, 0.8);

        .lock {
          position: absolute;
          top: -15%;
          left: 2%;
        }

        .main {
          margin-top: 3rem;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          color: $dark;
          width: 50%;
        }

        .sub {
          margin-top: 1rem;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $dark;
        }

        .compliance {
          margin-top: 2rem;

          div {
            margin-top: 15px;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: normal;
            color: $dark;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .university {
    margin-bottom: 3rem;

    &-menu {
      display: none;
    }

    &-banner {
      padding: 53px 0 0;
      background-size: 80%;

      .container {
        padding: 0;
      }

      &-content {
        flex-direction: column;
        padding-top: 0.5rem;

        &-main {
          width: 100%;
          margin-right: 0;
        }

        &-form {
          width: 100%;
          margin: 0;
          padding: 0;
        }
      }

      .help-connect {
        font-size: 22px;
        line-height: 1.18;
        padding: 5px 20px;
        text-align: center;
      }

      .dc {
        flex-direction: column;

        div:nth-child(1) {
          width: 236px;
          height: 100%;
          padding: 20px 16px 24px;
          font-size: 14px;
          margin-right: 30%;
          margin-bottom: 20px;
          line-height: 1.29;
        }

        div:nth-child(2) {
          width: 214px;
          height: 100%;
          padding: 20px 16px 24px;
          font-size: 14px;
          line-height: 1.29;
          margin-right: 2rem;
        }
      }

      .gi {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        div {
          width: 206px;
          height: 100%;
          margin-left: 0;
          padding: 20px 37px 18px 16px;
          font-size: 14px;
          line-height: 1.29;
        }
      }
    }

    &-benefits {
      .top {
        &-left-card {
          display: flex;
          justify-content: center;
          margin-bottom: 5rem;

          .shadow {
            width: 265px;
            margin-top: 20px;

            .main {
              padding: 24px 31px 30px 24px;

              div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                img {
                  margin-right: 10px;
                }

                .khibra {
                  line-height: 1.27;
                  color: $dark;
                }
              }

              .inner {
                margin: 0;
                font-size: 26px;
                line-height: 1.15;
              }
            }
          }
        }

        &-card {
          align-items: center;
          margin-bottom: 2rem;
          border: 0;

          img {
            margin-bottom: 15px;
            height: 42px;
            width: 42px;
          }

          .inner {
            font-size: 16px;
            text-align: center;
          }
        }
      }

      .bottom {
        &-card {
          align-items: center;
          padding-top: 0;
          margin-bottom: 2rem;

          img {
            margin-bottom: 15px;
            height: 42px;
            width: 42px;
          }

          .inner {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
          }
        }

        &-card:nth-child(2),
        &-card:nth-child(3) {
          border-left: 0;
        }
      }
    }

    &-partners {
      padding: 0;
      margin-top: 2rem;

      &-bg {
        height: 100%;
        width: 100%;
        padding: 10px 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .content {
          position: relative;
          top: unset;
          width: 100%;

          .title {
            line-height: 1.27;
          }

          .body {
            font-size: 14px;
            line-height: 1.5;
          }
        }

        .images {
          display: none;

          &-sm {
            align-items: center;

            div {
              margin-bottom: 3rem;
            }
          }
        }
      }
    }

    &-launch {
      padding: 10px;

      .title {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 1.27;
      }

      .sub-title {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    &-protection {
      margin-top: -250px;

      &-form {
        padding: 0;
        margin-top: 2rem;

        div {
          width: 100%;
        }
      }

      .security {
        &-card {
          padding: 65px 24px 40px;

          .lock {
            top: -8%;

            img {
              height: 95px;
              width: 95px;
            }
          }

          .main {
            line-height: 1.27;
            width: 70%;
          }

          .sub {
            margin-top: 0.3rem;
            font-size: 16px;
            line-height: 1.5;
            width: 80%;
          }

          .compliance {
            margin-top: 2rem;

            div {
              font-size: 18px;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}
</style>

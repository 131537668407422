<template>
  <Button type="big" class="font-weight-bold" @clicked="goToApp()"
    >Login / Signup</Button
  >
</template>

<script>
import Button from "@/components/util/Button";
export default {
  name: "LoginButton",

  components: {
    Button,
  },

  methods: {
    goToApp() {
      window.open(process.env.VUE_APP_URL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

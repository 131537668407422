export default {
  scrollPageToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },

  scrollToElementWithinElement(parentElemId, elementId) {
    const { top, right, bottom, left } = document.getElementById(elementId).getBoundingClientRect();

    document.getElementById(parentElemId).scrollTo({
      top,
      right,
      bottom,
      left: left - 40,
      behavior: 'smooth',
    });
  },

  scrollToElement(elementId) {
    const { top, right, bottom, left } = document.getElementById(elementId).getBoundingClientRect();
    window.scrollTo({
      top,
      right,
      bottom,
      left: left,
      behavior: 'smooth',
    });
  },

  mailChimpPostUrl() {
    return "https://getkhibra.us7.list-manage.com/subscribe/post?u=cf19a159fe5c0d6871229aab2&amp;id=eb234038f3";
    // return "https://gmail.us7.list-manage.com/subscribe/post?u=18bce6a0720e7e91f100bc59f&amp;id=eddd06ace0";
  }
};

<template>
  <section>
    <div class="banner">
      <div class="text">
        KHIBRA is in the business of fast-tracking <br />
        young talent into the job market
      </div>
    </div>

    <div class="container empower">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="empower-card">
            <div class="empower-mini-card">
              <div class="text">
                <div>Empower</div>
                <div>Access</div>
                <div>Succeed</div>
              </div>

              <img
                src="../assets/02-brand-logo-symbol.png"
              srcset="
                ../assets/02-brand-logo-symbol@2x.png 2x,
                ../assets/02-brand-logo-symbol@3x.png 3x
              "
                class="img-fluid"
                alt=""
              />
            </div>

            <div class="empower-content">
              Gone are the days when young talented students struggle to find
              internships, fresh graduates give up on their job search, and
              young professionals get stuck with their first jobs. Believe us,
              we have been through this and understand how painful it is.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="start">
      <div class="container">
        <div class="row">
          <div class="col-md-6 start-step">
            <div class="ss">
              Now, <span class="break">we are stepping in</span>
            </div>

            <div class="ss">to change things for the better</div>

            <div class="ss">
              <b>KHIBRA</b> empowers the youth by providing them with
              easy-to-use tools to:
            </div>

            <div class="mt-4">
              <ul class="ul">
                <li>Build a strong profile</li>
                <li>
                  Access opportunities (full-time, part-time, internships,
                  training programs, and co-ops)
                </li>
                <li>
                  Get approached by employers across the Middle East, all under
                  a single platform
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-6 start-form">
            <RegForm bg="white" class="w" :displayToggler="true" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RegForm from "@/components/util/RegForm";

export default {
  components: {
    RegForm,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.banner {
  height: 534px;
  background-color: #f9fbff;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
  }
}

.empower {
  margin-top: -150px;

  &-card {
    position: relative;
    height: 656px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(27px);
    backdrop-filter: blur(27px);
    box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
    background-color: $blue;
  }

  &-content {
    position: absolute;
    bottom: 10%;
    padding: 70px 70px 0 50px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }

  &-mini-card {
    position: absolute;
    top: 3%;
    left: -5%;
    width: 100%;
    padding: 30px 10px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(27px);
    backdrop-filter: blur(27px);
    box-shadow: 25px 40px 30px 1px rgba(0, 0, 0, 0.12);
    background-image: linear-gradient(to left, #f9fbff, #ffffff 0%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      font-size: 69px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: 2px;
      color: $blue;
      text-transform: capitalize;
    }
  }
}

.start {
  margin-top: 10rem;
  margin-bottom: 10rem;

  &-step {
    .ss:nth-child(1) {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      color: $blue;
    }

    .ss:nth-child(2) {
      margin-top: 10px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    }

    .ss:nth-child(3) {
      margin-top: 3rem;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
    }

    .ul {
      list-style: none;
      margin: 0;
      padding-left: 20px;

      li {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;

        &::before {
          content: "\2022";
          color: $blue;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  &-form {
    .w {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    height: 250px;

    .text {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .empower {
    margin-top: 0;
    background: $blue;

    &-card {
      height: 550px;
      border-radius: 0;
      background: unset;
    }

    &-content {
      bottom: 3%;
      padding: 20px;
      font-size: 16px;
      text-align: justify;
    }

    &-mini-card {
      top: -10%;
      left: 0;
      flex-direction: column;

      .text {
        font-size: 46px;
        letter-spacing: 1.33px;
        order: 2;
        margin-top: 20px;
      }

      img {
        order: 1;
        height: 126px;
      }
    }
  }

  .start {
    margin-top: 5rem;
    margin-bottom: 10rem;

    &-step {
      .break {
        display: block;
      }

      .ss:nth-child(1) {
        font-size: 28px;
      }

      .ss:nth-child(2) {
        margin-top: 5px;
        font-size: 18px;
        line-height: 1.5;
      }

      .ss:nth-child(3) {
        margin-top: 1rem;
        font-size: 18px;
        line-height: 1.33;
      }

      .ul {
        li {
          font-size: 16px;
          line-height: 1.6;
          margin-bottom: 20px;
        }
      }
    }

    &-form {
      padding: 0;

      .w {
        width: 100%;
      }
    }
  }
}
</style>

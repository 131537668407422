import Vue from 'vue';
import VueRouter from 'vue-router';

import GTMService from "./gtm";

import About from "@/components/About.vue";
import Companies from "@/components/companies/Companies.vue";
import Contact from "@/components/Contact.vue";
import Employers from "@/components/employers/Employers.vue";
import Students from "@/components/students/Students.vue";
import Universities from "@/components/universities/Universities.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Students,
    name: "Home",
  },
  {
    path: '/about',
    component: About,
    name: "About",
  },
  {
    path: '/companies',
    component: Companies,
    name: "Companies",
  },
  {
    path: '/contact',
    component: Contact,
    name: "Contact us",
  },
  {
    path: '/employers',
    component: Employers,
    name: "Employers",
  },
  {
    path: '/students',
    component: Students,
    name: "Students",
  },
  {
    path: '/universities',
    component: Universities,
    name: "Universities",
  },
  {
    path: '*',
    component: Students
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition;
    } else if(to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// Log page accessed to GTM
const gtm = new GTMService();

router.afterEach((to) => {
  gtm.pageView(to.fullPath, to.name);
});

export default router;

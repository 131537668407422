<template>
  <div class="pb-5">
    <div class="companies">
      <div class="container">
        <div class="banner">
          <img
            src="../../assets/logo-symbol.png"
            srcset="
              ../../assets/logo-symbol@2x.png 2x,
              ../../assets/logo-symbol@3x.png 3x
            "
            class="img-fluid"
            alt=""
          />
          <span class="d-md-none kh">Khibra</span>
          <div class="companies-title">KHIBRA Partners</div>
          <div class="companies-sub-title">
            <span>Our employer partners are always on the lookout</span>
            <span>for talent. Join them!</span>
          </div>
        </div>
      </div>
    </div>

    <div class="companies-info">
      Access a variety of industries under one platform
    </div>

    <div class="container mt-5">
      <div class="row">
        <div class="col-6 col-md-4 companies-card">
          <div class="companies-card-img">
            <img
              src="../../assets/sidf.png"
              srcset="
                ../../assets/sidf@2x.png 2x,
                ../../assets/sidf@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="companies-card-text">Development Bank</div>
        </div>

        <div class="col-6 col-md-4 companies-card">
          <div class="companies-card-img">
            <img
              src="../../assets/fa-stc-cmyk-pos-removebg-preview.png"
              srcset="
                ../../assets/fa-stc-cmyk-pos-removebg-preview@2x.png 2x,
                ../../assets/fa-stc-cmyk-pos-removebg-preview@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="companies-card-text">Telecom</div>
        </div>

        <div class="col-6 col-md-4 companies-card">
          <div class="companies-card-img">
            <img
              src="../../assets/ajar-logo.png"
              srcset="
                ../../assets/ajar-logo@2x.png 2x,
                ../../assets/ajar-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="companies-card-text">Fintech</div>
        </div>

        <div class="col-6 col-md-4 companies-card">
          <div class="companies-card-img">
            <img src="../../assets/nbklogo.svg" class="img-fluid" alt="" />
          </div>
          <div class="companies-card-text">Bank</div>
        </div>

        <div class="col-6 col-md-4 companies-card">
          <div class="companies-card-img">
            <img
              src="../../assets/deliveroo-logo.png"
              srcset="
                ../../assets/deliveroo-logo@2x.png 2x,
                ../../assets/deliveroo-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="companies-card-text">Technology</div>
        </div>

        <div class="col-6 col-md-4 companies-card">
          <div class="companies-card-img">
            <img
              src="../../assets/seera-group-removebg-preview.png"
              srcset="
                ../../assets/seera-group-removebg-preview@2x.png 2x,
                ../../assets/seera-group-removebg-preview@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="companies-card-text">Travel</div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-5 container p-0">
      <div class="companies-rated">
        <div>Find jobs by top rated employers</div>
        <div>Recommended by universities across the Middle East</div>
        <Button
          type="big"
          color="transparent"
          class="text-white border"
          @clicked="goToApp()"
          >Get Started</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/util/Button";

export default {
  name: "Companies",
  components: {
    Button,
  },
  methods: {
    goToApp() {
      window.open(process.env.VUE_APP_URL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.companies {
  height: 406px;
  background-color: #f3f7ff;
  position: relative;

  .banner {
    position: absolute;
    bottom: 20%;
    margin-left: 50px;
  }

  &-title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: $dark;
    margin-top: 20px;
  }

  &-sub-title {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $dark;
    margin-top: 20px;

    span {
      display: block;
    }
  }

  &-info {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $blue;
    margin-top: 5rem;
  }

  &-card {
    height: 254px;
    border-radius: 4px;
    box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0);
    background-color: rgba(179, 182, 189, 0.04);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      box-shadow: 0 0 2px $dark;
    }

    &-img {
      height: 35%;
    }

    &-text {
      margin-top: 2.5rem;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $blue;
    }
  }

  &-rated {
    height: 259px;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(27px);
    backdrop-filter: blur(27px);
    box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
    background-color: $blue;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div:nth-child(1) {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      margin-bottom: 20px;
    }

    div:nth-child(2) {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .companies {
    text-align: center;
    height: 250px;

    .banner {
      img {
        margin-top: -20px;
      }

      .kh {
        color: $blue;
        padding: 5rem 10px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
      }
    }

    &-title {
      font-size: 18px;
      line-height: 1.5;
      margin-top: 20px;
    }

    &-sub-title {
      font-size: 14px;
      line-height: 1.5;
      margin-top: 5px;
    }

    &-info {
      font-size: 22px;
      line-height: 1.18;
      color: $dark;
      margin-top: 3rem;
    }

    &-card {
      border-radius: 0;
      height: 200px;

      &-text {
        font-size: 10px;
      }
    }

    &-rated {
      height: 200px;
      border-radius: 0;
      padding: 0 !important;
      margin: 0 !important;

      div:nth-child(1) {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 5px;
      }

      div:nth-child(2) {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}
</style>

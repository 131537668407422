<template>
  <img
    src="../../assets/02-brand-logo.png"
    srcset="../../assets/02-brand-logo@2x.png 2x, ../../assets/02-brand-logo@3x.png 3x"
    class="img-fluid"
    alt="Khibra logo"
  />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="container partners">
    <div class="partners-title">University Partners</div>
    <div class="partners-sub-title">
      KHIBRA partners with leading universities across the Middle East to give
      their students access to the latest job opportunities
    </div>
    <div class="row partners-logo">
      <div class="col-4 col-md-auto">
        <img
          src="../assets/ack-logo.png"
          srcset="
            ../assets/ack-logo@2x.png 2x,
            ../assets/ack-logo@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />
      </div>

      <div class="col-4 col-md-auto">
        <img
          src="../assets/aub-logo.png"
          srcset="
            ../assets/aub-logo@2x.png 2x,
            ../assets/aub-logo@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />
      </div>

      <div class="col-4 col-md-auto">
        <img
          src="../assets/k-tech-logo.png"
          srcset="
            ../assets/k-tech-logo@2x.png 2x,
            ../assets/k-tech-logo@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />
      </div>

      <div class="col-4 col-md-auto">
        <img
          src="../assets/auk-logo.png"
          srcset="
            ../assets/auk-logo@2x.png 2x,
            ../assets/auk-logo@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />
      </div>

      <div class="col-4 col-md-auto">
        <img
          src="../assets/bibf-logo.png"
          srcset="
            ../assets/bibf-logo@2x.png 2x,
            ../assets/bibf-logo@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />
      </div>

      <div class="col-4 col-md-auto">
        <img
          src="../assets/kcst-logo.png"
          srcset="
            ../assets/kcst-logo@2x.png 2x,
            ../assets/kcst-logo@3x.png 3x
          "
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.partners {
  height: 385px;
  padding: 72px;
  border-radius: 8px;
  box-shadow: 0 20px 30px 1px rgba(0, 0, 0, 0.02);
  background-color: rgba(0, 74, 255, 0.04);

  &-title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: $dark;
  }

  &-sub-title {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $dark;
    margin-top: 20px;
    width: 58%;
  }

  &-logo {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .partners {
    height: unset;
    padding: 40px;
    border-radius: 0;

    &-title {
      font-size: 22px;
      line-height: 1.18;
      text-align: center;
    }

    &-sub-title {
      font-size: 14px;
      line-height: 1.5;
      margin-top: 20px;
      width: unset;
      text-align: center;
    }

    &-logo {
      img {
        margin-bottom: 40px;
      }
    }
  }
}
</style>

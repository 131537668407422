<template>
  <header>
    <div class="container d-none d-md-block">
      <div class="menu">
        <div class="col-md-6 d-flex align-items-center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>

          <RouterLink to="/students" v-slot="{ href, navigate, isActive }">
            <span :class="{ active: isActive }">
              <a :href="href" @click="navigate"> Students </a>
            </span>
          </RouterLink>

          <RouterLink to="/employers" v-slot="{ href, navigate, isActive }">
            <span :class="{ active: isActive }">
              <a :href="href" @click="navigate"> Employers </a>
            </span>
          </RouterLink>

          <RouterLink to="/universities" v-slot="{ href, navigate, isActive }">
            <span :class="{ active: isActive }">
              <a :href="href" @click="navigate"> Universities </a>
            </span>
          </RouterLink>
        </div>

        <div class="col-md-6 d-flex align-items-center">
          <RouterLink to="/about" v-slot="{ href, navigate, isActive }">
            <span :class="{ active: isActive }">
              <a :href="href" @click="navigate"> About </a>
            </span>
          </RouterLink>
          <RouterLink to="/contact" v-slot="{ href, navigate, isActive }">
            <span :class="{ active: isActive }">
              <a :href="href" @click="navigate"> Contact us </a>
            </span>
          </RouterLink>
          <span>
            <LoginButton />
          </span>
        </div>
      </div>
    </div>

    <MobileHeader />
  </header>
</template>

<script>
import LoginButton from "@/components/util/LoginButton";
import Logo from "@/components/util/Logo";
import MobileHeader from "@/components/MobileHeader";

export default {
  name: "Header",

  components: {
    LoginButton,
    Logo,
    MobileHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

@media (min-width: 768px) {
  header {
    height: 75px;
    padding: 20px 0;

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $dark;

      span,
      span a {
        margin-right: 15px;
        margin-left: 15px;
        color: $dark;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:focus {
          opacity: 0.9;
        }

        &.active > a {
          font-weight: bold;
          color: $blue;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"container d-none d-md-block"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"col-md-6 d-flex align-items-center"},[_c('RouterLink',{attrs:{"to":"/"}},[_c('Logo')],1),_c('RouterLink',{attrs:{"to":"/students"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('span',{class:{ active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Students ")])])]}}])}),_c('RouterLink',{attrs:{"to":"/employers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('span',{class:{ active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Employers ")])])]}}])}),_c('RouterLink',{attrs:{"to":"/universities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('span',{class:{ active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Universities ")])])]}}])})],1),_c('div',{staticClass:"col-md-6 d-flex align-items-center"},[_c('RouterLink',{attrs:{"to":"/about"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('span',{class:{ active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" About ")])])]}}])}),_c('RouterLink',{attrs:{"to":"/contact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('span',{class:{ active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Contact us ")])])]}}])}),_c('span',[_c('LoginButton')],1)],1)])]),_c('MobileHeader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
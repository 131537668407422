<template>
  <header class="d-md-none">
    <div class="bar">
      <RouterLink class="col-6" to="/">
        <Logo />
      </RouterLink>

      <div class="col-6 text-right">
        <div
          v-if="menuExpanded"
          class="closed"
          @click.prevent.stop="toggleMenu()"
        >
          <img
            src="../assets/05-icons-menu-close.png"
            srcset="
              ../assets/05-icons-menu-close@2x.png 2x,
              ../assets/05-icons-menu-close@3x.png 3x
            "
            alt=""
          />
        </div>

        <div v-else class="toggler">
          <img
            src="../assets/05-icons-menu.png"
            srcset="
              ../assets/05-icons-menu@2x.png 2x,
              ../assets/05-icons-menu@3x.png 3x
            "
            class="pointer"
            alt=""
            @click.prevent.stop="toggleMenu()"
          />
        </div>
      </div>
    </div>

    <div v-if="menuExpanded" class="items">
      <div class="item">
        <RouterLink to="/" v-slot="{ href, isExactActive }">
          <div>
            <div class="main">
              <div :class="{ text: true, active: isExactActive }">
                <a :href="href" @click.prevent.stop="openPage(href)">
                  Students
                </a>
              </div>

              <div class="toggler">
                <img
                  v-if="isExactActive"
                  src="../assets/05-icons-minus.svg"
                  alt=""
                />
                <img v-else src="../assets/05-icons-plus.svg" alt="" />
              </div>
            </div>

            <div v-if="isExactActive" class="sub">
              <div class="active">Overview</div>
              <div @click.prevent.stop="scrollToElem('how-it-works')">
                How it works
              </div>
              <div @click.prevent.stop="scrollToElem('uni-partners')">
                Employer partners
              </div>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="item">
        <RouterLink to="/employers" v-slot="{ href, isActive }">
          <div>
            <div class="main">
              <div :class="{ text: true, active: isActive }">
                <a :href="href" @click.prevent.stop="openPage(href)">
                  Employers
                </a>
              </div>

              <div class="toggler">
                <img
                  v-if="isActive"
                  src="../assets/05-icons-minus.svg"
                  alt=""
                />
                <img v-else src="../assets/05-icons-plus.svg" alt="" />
              </div>
            </div>

            <div v-if="isActive" class="sub">
              <div class="active">Overview</div>
              <div @click.prevent.stop="scrollToElem('prod-feat')">
                Product Features
              </div>
              <div @click.prevent.stop="scrollToElem('career-fairs')">
                Career Fairs
              </div>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="item">
        <RouterLink to="/universities" v-slot="{ href, isActive }">
          <div>
            <div class="main">
              <div :class="{ text: true, active: isActive }">
                <a :href="href" @click.prevent.stop="openPage(href)">
                  Universities
                </a>
              </div>

              <div class="toggler">
                <img
                  v-if="isActive"
                  src="../assets/05-icons-minus.svg"
                  alt=""
                />
                <img v-else src="../assets/05-icons-plus.svg" alt="" />
              </div>
            </div>

            <div v-if="isActive" class="sub">
              <div class="active">Overview</div>
              <div @click.prevent.stop="scrollToElem('benefits')">Benefits</div>
              <div @click.prevent.stop="scrollToElem('partners')">Partners</div>
              <div @click.prevent.stop="scrollToElem('data-protection')">
                Data protection
              </div>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="item">
        <RouterLink to="/about" v-slot="{ href, isActive }">
          <div class="main">
            <div :class="{ text: true, active: isActive }">
              <a :href="href" @click.prevent.stop="openPage(href)"> About </a>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="item">
        <RouterLink to="/contact" v-slot="{ href, isActive }">
          <div class="main">
            <div :class="{ text: true, active: isActive }">
              <a :href="href" @click.prevent.stop="openPage(href)">
                Contact us
              </a>
            </div>
          </div>
        </RouterLink>
      </div>

      <div class="mt-5 mb-5 login-menu">
        <div class="main">Find jobs by top rated employers</div>
        <div class="other mt-1">
          Recommended by universities across the Middle East
        </div>
        <div class="text-center mt-3">
          <LoginButton />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import util from "@/scripts/util";
import LoginButton from "@/components/util/LoginButton";
import Logo from "@/components/util/Logo";

export default {
  name: "Header",

  components: {
    LoginButton,
    Logo,
  },

  data() {
    return {
      menuExpanded: false,
    };
  },

  methods: {
    toggleMenu() {
      this.menuExpanded = !this.menuExpanded;
    },

    scrollToElem(elemId) {
      this.toggleMenu();
      util.scrollToElement(elemId);
    },

    openPage(href) {
      this.toggleMenu();
      this.$router.push(href);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

@media only screen and (max-width: 768px) {
  header {
    .menu {
      display: none;
    }

    .bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      background-color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(28, 47, 143, 0.1);
    }

    .closed {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      background-color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
    }

    .toggler {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      background-color: #f2f6ff;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
    }

    .items {
      width: 100vw;
      height: 70vh;
      overflow-y: auto;
      background-color: #ffffff;
      position: absolute;
      z-index: 100;

      .item {
        padding: 20px;
        border-bottom: 1px solid #d3d3d3;

        .main {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .text {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $dark;

          &.active {
            font-weight: bold;
            color: $blue;
          }

          a {
            color: inherit;
          }
        }

        .sub {
          div {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            opacity: 0.65;
            padding: 3px 0;

            &.active {
              opacity: 1;
              font-weight: bold;
            }
          }
        }
      }
    }

    .login-menu {
      .main {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
      }

      .other {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
      }
    }
  }
}
</style>

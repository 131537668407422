<template>
  <div class="container mb-5">
    <div class="looking">Looking for an internship or a job?</div>

    <div class="mt-3 mt-md-5 row d-flex align-items-center">
      <div class="col-md-6 p-0 order-2 order-md-1">
        <div class="details">
          <div>Companies across the <b>Middle East</b></div>
          <div>recruit here!</div>
          <div>
            No matter where you graduated from, KHIBRA helps connect you to the
            best opportunities in the region
          </div>
          <Button
            @clicked="goToCompanies()"
            type="big"
            color="transparent"
            class="text-white border"
            >View all companies</Button
          >
        </div>
      </div>

      <div class="col-md-6 companies order-1 order-md-2">
        <div>Check out who's hiring on KHIBRA</div>

        <div class="row logos">
          <div class="col-4 col-md-3">
            <img
              src="../../assets/nbklogo.svg"
              srcset="
                ../../assets/nbklogo@2x.png 2x,
                ../../assets/nbklogo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>

          <div class="col-4 col-md-3">
            <img
              src="../../assets/fa-stc-cmyk-pos-removebg-preview.png"
              srcset="
                ../../assets/fa-stc-cmyk-pos-removebg-preview@2x.png 2x,
                ../../assets/fa-stc-cmyk-pos-removebg-preview@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>

          <div class="col-4 col-md-3">
            <img
              src="../../assets/seera-group-removebg-preview.png"
              srcset="
                ../../assets/seera-group-removebg-preview@2x.png 2x,
                ../../assets/seera-group-removebg-preview@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>

          <div class="col-4 col-md-3">
            <img
              src="../../assets/sidf.png"
              srcset="
                ../../assets/sidf@2x.png 2x,
                ../../assets/sidf@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>

          <div class="col-4 col-md-3">
            <img
              src="../../assets/gb-logo.svg"
              srcset="
                ../../assets/gb-logo@2x.png 2x,
                ../../assets/gb-logo@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>

          <div class="col-4 col-md-3">
            <img
              src="../../assets/zain-logo-logotype-2.png"
              srcset="
                ../../assets/zain-logo-logotype-2@2x.png 2x,
                ../../assets/zain-logo-logotype-2@3x.png 3x
              "
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/util/Button";

export default {
  name: "Internship",

  components: {
    Button,
  },

  methods: {
    goToCompanies() {
      this.$router.push({
        name: "Companies",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.looking {
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  width: 40%;
  color: $dark;
}

.details {
  width: 80%;
  padding: 32px;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);
  background-color: $blue;
  color: #ffffff;
  text-align: center;

  div:nth-child(1) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    margin-bottom: 1rem;
  }

  div:nth-child(2) {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    margin-bottom: 1rem;
  }

  div:nth-child(3) {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-bottom: 2rem;
  }
}

.companies {
  div:nth-child(1) {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .looking {
    font-size: 22px;
    line-height: 1.18;
    width: 100%;
    text-align: center;
    padding: 0 5rem;
  }

  .details {
    width: 100%;
    margin: 0;
    border-radius: 0;

    div:nth-child(1) {
      line-height: 1.5;
    }

    div:nth-child(2) {
      font-size: 22px;
      line-height: 1.18;
    }

    div:nth-child(3) {
      font-size: 14px;
      line-height: 1.29;
    }
  }

  .companies {
    div:nth-child(1) {
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
    }

    .logos {
      margin-bottom: 3rem;
    }
  }
}
</style>

export default class GTMService {
  constructor() {
    if(process.browser) {
      window.dataLayer = window.dataLayer || [];
    }
  }

  pageView(pagePath, pageTitle) {
    window.dataLayer.push({
      pagePath,
      pageTitle: pageTitle || document.querySelector('title')?.innerText,
      event: 'pageView',
    });
  }

  logEvent(event) {
    window.dataLayer.push({
      event,
    });
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"d-md-none"},[_c('div',{staticClass:"bar"},[_c('RouterLink',{staticClass:"col-6",attrs:{"to":"/"}},[_c('Logo')],1),_c('div',{staticClass:"col-6 text-right"},[(_vm.menuExpanded)?_c('div',{staticClass:"closed",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleMenu()}}},[_c('img',{attrs:{"src":require("../assets/05-icons-menu-close.png"),"srcset":require("../assets/05-icons-menu-close@2x.png") + " 2x, " + require("../assets/05-icons-menu-close@3x.png") + " 3x","alt":""}})]):_c('div',{staticClass:"toggler"},[_c('img',{staticClass:"pointer",attrs:{"src":require("../assets/05-icons-menu.png"),"srcset":require("../assets/05-icons-menu@2x.png") + " 2x, " + require("../assets/05-icons-menu@3x.png") + " 3x","alt":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleMenu()}}})])])],1),(_vm.menuExpanded)?_c('div',{staticClass:"items"},[_c('div',{staticClass:"item"},[_c('RouterLink',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
return [_c('div',[_c('div',{staticClass:"main"},[_c('div',{class:{ text: true, active: isExactActive }},[_c('a',{attrs:{"href":href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPage(href)}}},[_vm._v(" Students ")])]),_c('div',{staticClass:"toggler"},[(isExactActive)?_c('img',{attrs:{"src":require("../assets/05-icons-minus.svg"),"alt":""}}):_c('img',{attrs:{"src":require("../assets/05-icons-plus.svg"),"alt":""}})])]),(isExactActive)?_c('div',{staticClass:"sub"},[_c('div',{staticClass:"active"},[_vm._v("Overview")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('how-it-works')}}},[_vm._v(" How it works ")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('uni-partners')}}},[_vm._v(" Employer partners ")])]):_vm._e()])]}}],null,false,2194824339)})],1),_c('div',{staticClass:"item"},[_c('RouterLink',{attrs:{"to":"/employers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('div',[_c('div',{staticClass:"main"},[_c('div',{class:{ text: true, active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPage(href)}}},[_vm._v(" Employers ")])]),_c('div',{staticClass:"toggler"},[(isActive)?_c('img',{attrs:{"src":require("../assets/05-icons-minus.svg"),"alt":""}}):_c('img',{attrs:{"src":require("../assets/05-icons-plus.svg"),"alt":""}})])]),(isActive)?_c('div',{staticClass:"sub"},[_c('div',{staticClass:"active"},[_vm._v("Overview")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('prod-feat')}}},[_vm._v(" Product Features ")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('career-fairs')}}},[_vm._v(" Career Fairs ")])]):_vm._e()])]}}],null,false,125238070)})],1),_c('div',{staticClass:"item"},[_c('RouterLink',{attrs:{"to":"/universities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('div',[_c('div',{staticClass:"main"},[_c('div',{class:{ text: true, active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPage(href)}}},[_vm._v(" Universities ")])]),_c('div',{staticClass:"toggler"},[(isActive)?_c('img',{attrs:{"src":require("../assets/05-icons-minus.svg"),"alt":""}}):_c('img',{attrs:{"src":require("../assets/05-icons-plus.svg"),"alt":""}})])]),(isActive)?_c('div',{staticClass:"sub"},[_c('div',{staticClass:"active"},[_vm._v("Overview")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('benefits')}}},[_vm._v("Benefits")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('partners')}}},[_vm._v("Partners")]),_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.scrollToElem('data-protection')}}},[_vm._v(" Data protection ")])]):_vm._e()])]}}],null,false,3052841933)})],1),_c('div',{staticClass:"item"},[_c('RouterLink',{attrs:{"to":"/about"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('div',{staticClass:"main"},[_c('div',{class:{ text: true, active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPage(href)}}},[_vm._v(" About ")])])])]}}],null,false,3956677606)})],1),_c('div',{staticClass:"item"},[_c('RouterLink',{attrs:{"to":"/contact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('div',{staticClass:"main"},[_c('div',{class:{ text: true, active: isActive }},[_c('a',{attrs:{"href":href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openPage(href)}}},[_vm._v(" Contact us ")])])])]}}],null,false,4194126829)})],1),_c('div',{staticClass:"mt-5 mb-5 login-menu"},[_c('div',{staticClass:"main"},[_vm._v("Find jobs by top rated employers")]),_c('div',{staticClass:"other mt-1"},[_vm._v(" Recommended by universities across the Middle East ")]),_c('div',{staticClass:"text-center mt-3"},[_c('LoginButton')],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="emp-sect">
    <div v-if="formSubmitted" class="form-success">
      <img
        src="../../assets/email-sent.png"
        srcset="
          ../../assets/email-sent@2x.png 2x,
          ../../assets/email-sent@3x.png 3x
        "
        class="img-fluid"
        alt=""
      />
      <div class="main">Your message was sent successfully</div>
      <div class="sub">We will get back to you as soon as possible</div>
    </div>

    <div v-else :class="['emp-form', bg]">
      <div class="title mb-3">Get Started</div>

      <form
        :action="formPostUrl"
        method="post"
        id="reg-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div class="user-type" v-if="displayToggler">
          <div
            :class="{ active: userType == 'student' }"
            @click.prevent.stop="setUserType('student')"
          >
            Student
          </div>
          <div
            :class="{ active: userType == 'employer' }"
            @click.prevent.stop="setUserType('employer')"
          >
            Employer
          </div>
          <div
            :class="{ active: userType == 'university' }"
            @click.prevent.stop="setUserType('university')"
          >
            University
          </div>
        </div>

        <div class="form-row mb-3" v-if="formErrMsg">
          <div class="col-12 small text-center">{{ formErrMsg }}</div>
        </div>

        <div class="form-row mb-3 d-none">
          <div class="col-12">
            <input
              type="text"
              class="form-control form-input"
              placeholder="user type"
              name="USERTYPE"
              v-model="userType"
            />
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12">
            <input
              type="text"
              class="form-control form-input"
              placeholder="Name"
              name="FNAME"
              v-model="name"
            />
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              placeholder="Contact Number"
              name="PHONE"
              v-model="contactNum"
            />
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12">
            <input
              type="text"
              class="form-control"
              placeholder="Organization Name"
              name="ORGNAME"
              v-model="orgName"
            />
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12">
            <input
              type="email"
              class="form-control"
              placeholder="Email Address"
              name="EMAIL"
              v-model="email"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="col-12">
            <Button
              class="btn-block border mt-3"
              type="big"
              @clicked="submitForm()"
              >Get Started</Button
            >
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import util from "@/scripts/util";
import Button from "@/components/util/Button";

export default {
  name: "RegForm",

  components: {
    Button,
  },

  props: {
    bg: {
      type: String,
      default: "default",
    },
    displayToggler: {
      type: Boolean,
      default: false,
    },
    activeUserType: {
      type: String,
      default: "student",
    },
  },

  data() {
    return {
      name: "",
      orgName: "",
      contactNum: "",
      email: "",
      userType: this.activeUserType,
      formErrMsg: "",
      formSubmitted: false,
    };
  },

  computed: {
    formPostUrl() {
      return util.mailChimpPostUrl();
    },

    formValid() {
      return (
        this.userType &&
        this.name &&
        this.orgName &&
        this.contactNum &&
        this.email
      );
    },
  },

  methods: {
    setUserType(userType) {
      this.userType = userType;
    },

    submitForm() {
      if (this.formValid) {
        document.getElementById("reg-form").submit();
        this.formSubmitted = true;

        setTimeout(() => {
          this.clearFields();
        }, 3000);
      } else {
        this.formErrMsg = "All fields are required";
      }
    },

    clearFields() {
      this.name = "";
      this.orgName = "";
      this.contactNum = "";
      this.email = "";
      this.formErrMsg = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.emp-sect {
  margin-top: 20px;
}

.emp-form {
  padding: 20px;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  box-shadow: 0 40px 30px 1px rgba(0, 0, 0, 0.12);

  .user-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    div {
      cursor: pointer;
      padding: 5px 10px;

      &.active {
        border-radius: 4px;
      }
    }
  }

  &.default {
    background-color: $blue;
    color: #ffffff;

    .user-type {
      border: 1px solid #ffffff;

      div {
        &.active {
          background-color: #ffffff;
          color: $blue;
          border-radius: 4px;
        }
      }
    }

    input {
      background-color: $blue;
      color: #ffffff;
      border: solid 1px #3a73ff;

      &::placeholder {
        color: #ffffff;
      }

      &:focus {
        border: solid 1px #f3f7ff;

        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  &.white {
    background-color: #ffffff;
    color: $dark;

    .user-type {
      border: 1px solid $blue;

      div {
        &.active {
          background-color: $blue;
          color: #ffffff;
        }
      }
    }

    input {
      background-color: #ffffff;
      color: $dark;
      border: solid 1px #f1f1f5;

      &::placeholder {
        color: $dark;
      }

      &:focus {
        border: solid 1px $blue;

        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
  }

  input {
    height: 55px;

    &::placeholder {
      opacity: 0.6;
    }
  }

  Button {
    height: 55px;
  }
}

.form-success {
  width: 364px;
  height: 468px;
  padding: 118px 48px 103px 49px;
  border-radius: 12px;
  box-shadow: 0 40px 30px 2px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;

  img {
  }

  .main {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $dark;
  }

  .sub {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $dark;
  }
}

@media only screen and (max-width: 768px) {
  .emp-form {
    border-radius: 0;
  }
}
</style>

<template>
  <section>
    <div
      v-for="(step, index) in steps"
      :key="'step-' + index + 1"
      class="vertical-steps"
    >
      <div class="step">
        <div class="bullet"><span></span></div>
        <div class="number"><span class="d-md-none">Step</span> {{ index + 1 }}</div>
        <div class="description">{{ step }}</div>
      </div>

      <div v-if="index + 1 < steps.length" class="line"></div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.vertical-steps {
  font-family: $default-font;

  .step {
    display: flex;
    align-items: center;
  }

  .bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    padding: 4px;
    border: 1px solid $blue;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;

    span {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $blue;
      border-radius: 50%;
    }
  }

  .number {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $blue;
    margin-right: 20px;
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $dark;
    width: 50%;
  }

  .line {
    height: 110px;
    border-left: 1px solid $blue;
    margin-top: -15px;
    margin-bottom: -15px;
    margin-left: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .vertical-steps {
    .step {
      justify-content: center;
      flex-direction: column;
    }

    .bullet {
      display: none
    }

    .number {
      font-size: 22px;
      line-height: 1.18;
      margin-right: unset;
    }

    .description {
      width: 100%;
      text-align: center;
      padding-bottom: 30px;
    }

    .line {
      display: none;
    }
  }
}
</style>

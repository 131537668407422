<template>
  <div class="container contact">
    <div class="row main">
      <div class="col-md-6 text">
        <div class="gj">Great jobs start with a great conversation</div>
        <div class="cot">
          Contact our team to learn more about how KHIBRA can make a difference
          in your hiring process
        </div>
        <ContactForm class="d-md-none p-0" formId="mobile-contact-form" />
        <div class="d-flex justify-content-center d-md-none mt-5 pb-5 pb-md-0">
          <img class="img-fluid" src="../assets/logo-symbol-white.svg" alt="">
        </div>
        <!-- <div class="talk">Would like to talk to us instead?</div>
        <div class="cc">
          Our customer care representatives are just a phone call away
        </div>
        <div class="phone">(+965) 9990 0000</div> -->
      </div>

      <div class="col-md-6 d-none d-md-block">
        <ContactForm formId="desktop-contact-form" />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/util/ContactForm";

export default {
  name: "Contact",

  components: {
    ContactForm,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.contact {
  margin-top: 50px;
  margin-bottom: 50px;
  height: 744px;
  background: url("../assets/logo-shape.png") top right no-repeat,
    url("../assets/logo-symbol-white.png") bottom left no-repeat;
  background-color: $blue;
  color: #ffffff;

  .main {
    padding: 5rem 3rem 0 2rem;
  }

  .text {
    .gj {
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      width: 80%;
    }

    .cot {
      margin-top: 20px;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      padding-bottom: 30px;
      border-bottom: 1px solid #ffffff;
      width: 60%;
    }

    .talk {
      margin-top: 30px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
    }

    .cc {
      margin-top: 20px;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      width: 60%;
    }

    .phone {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    margin-top: 0;
    margin-bottom: 20px;
    height: 100%;
    background: url("../assets/logo-shape.png") top right no-repeat;
    background-size: 25%;
    background-color: $blue;

    .main {
      padding: 50px 0 0 0;
    }

    .text {
      padding: 0;

      .gj {
        font-size: 22px;
        line-height: 1.18;
        width: 80%;
        padding: 0 20px;
      }

      .cot {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.57;
        padding-bottom: 0;
        border-bottom: none;
        width: 80%;
        padding: 0 20px 20px;
        text-align: justify;
      }

      .talk {
        margin-top: 50px;
        text-align: center;
      }

      .cc {
        margin-top: 5px;
        font-size: 16px;
        line-height: 1.5;
        width: 100%;
        padding: 0 20px;
        text-align: center;
      }

      .phone {
        margin-top: 10px;
        margin-bottom: 50px;
        text-align: center;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="d-flex align-items-center">
      <img
        src="../../assets/logo-symbol.png"
            srcset="
              ../../assets/logo-symbol@2x.png 2x,
              ../../assets/logo-symbol@3x.png 3x
            "
        class="img-fluid"
        alt=""
      />
      <span class="logo-text ml-2 mt-3">khibra</span>
    </div>

    <div class="help">helps your organization find</div>
    <div class="right">the right candidates</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.logo-text {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: $blue;
}

.help {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.right {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .logo-text {
    font-size: 24px;
    line-height: 1.27;
  }

  .help {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    margin-top: 10px;
  }

  .right {
    font-size: 22px;
    line-height: 1.18;
    text-align: left;
  }
}
</style>
